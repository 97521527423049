import Vue from 'vue'
import { create } from 'vue-modal-dialogs'

import Alert from './common/Alert'
import Confirm from './common/Confirm'
import ConfirmPassword from './common/ConfirmPassword'
Vue.prototype.$alert = create(Alert, 'title', 'message')
Vue.prototype.$confirm = create(Confirm)
Vue.prototype.$confirmPassword = create(ConfirmPassword)

import BarcodeRequest from './BarcodeRequest'
import CreateAssetGroup from './CreateAssetGroup'
import DecommissionAsset from './DecommissionAsset'
import EditAssetType from './EditAssetType'
import EditCheck from './EditCheck'
import EditJobType from './EditJobType'
import EditAssetGroup from './EditAssetGroup'
import JobTools from './JobTools'
import NewAssetType from './NewAssetType'
import NewCheck from './NewCheck'
import NewJobType from './NewJobType'
import ViewEvidence from './ViewEvidence'
import ViewImage from './ViewImage'
import CreateTechnician from '@/views/user/modals/CreateTechnician'
import CreateAdmin from '@/views/user/modals/CreateAdmin'
import NewSupportTicket from './NewSupportTicket'
import NewSupportTicketAttachment from './NewSupportTicketAttachment'
import UploadProfilePic from './UploadProfilePic'
import UploadCompanyLogo from './UploadCompanyLogo'
import UploadClientLogo from './UploadClientLogo'
import UploadSiteLogo from './UploadSiteLogo'
import CompanyBranding from './CompanyBranding'
import ChecklistTools from '@/views/checklist/modals/Tools'
import UploadDocument from './UploadDocument'
import ReplaceDocument from './ReplaceDocument'
import DownloadJobHistoryReport from '@/views/user/modals/DownloadJobHistoryReport'
import TransferZone from '@/modals/TransferZone'
import EditSensorName from '@/modals/EditSensorName'
import RecoverDecommissionedAsset from '@/modals/RecoverDecommissionedAsset'
import EditAnswerComment from '@/modals/EditAnswerComment'
import CommentChanges from '@/modals/AnswerCommentChanges'
import ImpersonateUser from '@/modals/ImpersonateUser'
import AssignToParent from './AssignToParent';
import SignJob from "@/modals/SignJob.vue";
import JobSummaryReport from "@/modals/JobSummaryReport.vue";
import PredefinedValuesEditor from "@/modals/PredefinedValuesEditor.vue";

export let barcodeRequest = create(BarcodeRequest)
export let createAssetGroup = create(CreateAssetGroup)
export let decommissionAsset = create(DecommissionAsset)
export let editAssetType = create(EditAssetType, 'type')
export let editCheck = create(EditCheck)
export let editJobType = create(EditJobType, 'type')
export let editAssetGroup = create(EditAssetGroup, 'type')
export let jobTools = create(JobTools)
export let jobClosingComments = create(JobClosingComments)
export let signJob = create(SignJob)
export let jobSummaryReport = create(JobSummaryReport)
export let inspectionSummaryReport = create(InspectionSummaryReport)
export let newAssetType = create(NewAssetType)
export let predefinedValuesEditor = create(PredefinedValuesEditor)
export let newCheck = create(NewCheck)
export let newJobType = create(NewJobType)
export let assignToParent = create(AssignToParent)
export let viewEvidence = create(ViewEvidence)
export let viewImage = create(ViewImage, 'imageUrl')
export let createTechnician = create(CreateTechnician)
export let createAdmin = create(CreateAdmin)
export let newSupportTicket = create(NewSupportTicket)
export let newSupportTicketAttachment = create(NewSupportTicketAttachment)
export let uploadProfilePic = create(UploadProfilePic, 'userId')
export let uploadCompanyLogo = create(UploadCompanyLogo)
export let uploadClientLogo = create(UploadClientLogo)
export let uploadSiteLogo = create(UploadSiteLogo)
export let companyBranding = create(CompanyBranding)
export let checklistTools = create(ChecklistTools)
export let uploadDocument = create(UploadDocument, 'uploadPath')
export let replaceDocument = create(ReplaceDocument, 'uploadPath')
export let downloadJobHistoryReport = create(DownloadJobHistoryReport)
export let transferZone = create(TransferZone, 'zone');
export let editSensorName = create(EditSensorName, 'sensor')
export let recoverDecommissionedAsset = create(RecoverDecommissionedAsset, 'asset')
export let editAnswerComment = create(EditAnswerComment, 'answer')
export let commentChanges = create(CommentChanges, 'answer')
export let impersonateUser = create(ImpersonateUser)

// Recurring Jobs
import RecurringJobHelp from '@/views/recurring-job/modals/Help'
export let recurringJobHelp = create(RecurringJobHelp)

import SchedulePreview from '@/views/recurring-job/modals/SchedulePreview'
export let schedulePreview = create(SchedulePreview)

import TechnicianOverride from '@/views/recurring-job/modals/TechnicianOverride'
export let scheduledJobTechnicianOverride = create(TechnicianOverride)

import ChangeDate from '@/views/recurring-job/modals/ChangeDate'
export let scheduledJobChangeDate = create(ChangeDate)

import DeleteRecurringJob from '@/views/recurring-job/modals/Delete'
export let deleteRecurringJob = create(DeleteRecurringJob)

// Calendar (uses dedicated wrapper)
let calendarModal = component => create({
  component,
  props: ['eventProps'],
  wrapper: ['calendar']
})

import JobCalendarPopover from '@/views/job/calendar/JobPopover'
export let jobCalendarPopover = calendarModal(JobCalendarPopover)

import ScheduledJobCalendarPopover from '@/views/job/calendar/ScheduledJobPopover'
export let scheduledJobCalendarPopover = calendarModal(ScheduledJobCalendarPopover)

import TwoFactorAuthentication from '@/views/user/modals/TwoFactorAuthentication'
import InspectionSummaryReport from "@/modals/InspectionSummaryReport.vue";
import JobClosingComments from "@/modals/JobClosingComments.vue";
export let twoFactorAuthentication = create(TwoFactorAuthentication)
